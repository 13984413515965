/* You can add global styles to this file, and also import other style files */

@use '../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

* { font-family: sans-serif; }
a { color: #15a0cf; outline: 0; }
i { color: #15a0cf; }
body {padding: 0; margin: 0; font-family : sans-serif;}
body {overflow-x: hidden;}
body, html {height: 100%; font-size: 1em; }
h3 { padding: 0 20px; }
.clear {display: block; height: 0px; content: ""; clear: both;}
.col-centered{ float: none; margin: 0 auto;}

.form_header { display: block; }
hr { width: 550px; }
hr.top { margin-top: 15px; }
hr.mbottom { margin-bottom: 25px;}

label { width: 280px; }
.link { text-decoration: underline; color: #0044c3 }
.label-info { width: 260px; display: inline-block; }
.link { cursor: pointer;}
.icon_link { 
  cursor: pointer;
}
.icon_link:hover { 
  color: blue;
}
/*===================================*/
/* NG form styles*/
/*===================================*/
input.ng-touched.ng-invalid:not(form), 
p-autoComplete.ng-touched.ng-invalid:not(form), 
textarea.ng-touched.ng-invalid:not(form), 
p-dropdown.ng-touched.ng-invalid:not(form) { 
  background-color: lightpink
}
input.ng-invalid:not(form),
p-autoComplete.ng-invalid:not(form),
textarea.ng-invalid:not(form),
p-dropdown.ng-invalid:not(form) {
  border-bottom: 2px solid red !important;
}
p-table td { vertical-align: top; }
/*===================================*/
/* Form and inputs */
/*===================================*/
form .mat-form-field { width: 260px; }
form input { width: 260px; }
.normal-input { width: 260px; }
form .mat-form-field.big-mat-form-field { width: 530px; }
form .stretch { width: 100%; }
form .big-input, .big-input { width: 530px;}

input { line-height: 26px; }
.input-normal { width: 260px; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
/*===================================*/
/* Material design styles*/
/*===================================*/
mat-sidenav { 
  width: 330px; 
}
/* removing the transformation because position:fixed elements are not working.*/
.mat-sidenav-content {
  -webkit-transform: none !important;
  transform: none !important;
}

mat-tab-division .mat-inc-bar { width: 14% !important; }
mat-tab-division .mat-tab-label { min-width: 0 !important; padding:1% !important;}
.mat-tab-label ,.mat-tab-label-active { min-width: 0 !important; /* same comments as the first solution */}
/* This makes the main header of the site with white font */
.mat-toolbar, .mat-toolbar a { 
  color: white;
  text-align: center; 
}  
.menuitem .mat-list-text { padding: 0px; }
.mat-list, .mat-nav-list { padding-top: 0px !important; display: block; }
/*===================================*/
/* FLEX styles*/
/*===================================*/
.fill-remaining-space {
  /* This fills the remaining space, by using flexbox. 
  Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}
.hidden-router{
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
}
/*===================================*/
/* Menu styles*/
/*===================================*/
.menuitem a {
  line-height: 48px;
}
/*===================================*/
/* FAB material button style */
/* ================================================== */
.mat-fab {
  z-index: 1;
}
/*===================================*/
/* FAB button style */
/* ================================================== */
/* Header FAB button */
.smd-fab-speed-dial-container {
  height: 56px;
}
/* Other FAB button*/
ngc-float-button {
  z-index: 1;
}
ngc-float-button .active {
  background-color: transparent;
}
.addItemButton {
  z-index: 2;
}
/* ================================================== */
/* Right speed dial icons */
/* ================================================== */
.mat-mini-fab {
  overflow: hidden;
}
smd-fab-actions .mat-mini-fab {
  min-width: 0;
  width: 56px !important;
  height: 56px !important;
}

.mat-icon.svgFabIcon {
    display: inline-block;
    height: 72px !important;
    width: 72px !important;
    position: relative !important;
    left: -8px !important;
    top: -8px !important;
}

.active {
  background-color: lightgray;
}
/* ================================================== */
/* primeng css */
/* ================================================== */
.p-gridrowl {
  left:25px;
}
.ui-dropdown { min-width: 260px; }
.ui-multiselect{
  min-width: 260px;
  min-height: 30px;
}
p-dialog {
  z-index: 102;
}

.view-side-form .side-form {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    overflow: auto;
    background: #fff;
    padding: 20px;
    border-left: 1px solid #e0e0e0;
}

.custom_scroll .ui-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4; 
}
.custom_scroll .ui-scrollpanel-bar {
    background-color: #1976d2; 
    opacity: 1; 
    transition: background-color .3s; 
}
.custom_scroll .ui-scrollpanel-bar:hover {
    background-color: #135ba1;
}
/* Setting non-scrollable-table over the wrapping div around the table helps removing the scrolls */
.non-scrollable-table .ui-table-wrapper {
  overflow-x: visible !important;
}
@media only screen and (max-width: 700px) {
  body .ui-table .ui-table-tbody > tr:nth-child(even) {
    background-color: #f1f1f9;
  }
}
p-table {
  width: 100% !important;
}
